import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Tooltip } from 'sw-ui';

import lodashReplaces from '../../../../../bi/utils/lodashReplaces';
import { getAvia } from '../../../../../bi/utils/air';

import Suggest from '../../../../../components/Suggest';
import Input from '../../../../../components/input';
import AjaxButton from '../../../../../components/ajaxButton';

import {
  FIELDS,
  LABEL_CONTRACTS_CODE,
  AVIA_COMPANY_CONTRACT,
  AVIA_PROGRAMM_CONTRACT,
  AVIA_CONTRACT_FIELDS,
  AVIA_COMPANY_CONTRACT_MILE,
  AVIA_COMPANY_CONTRACT_S7,
  DEFAULT_AVIA_COMPANY_CONTRACT,
} from '../../../../../bi/constants/profile';
import { DECISION_BUTTONS_CAMEL } from '../../../../../bi/constants/decisionButtons';
import { AIRLINE_PROVIDERS } from '../../../../../bi/constants/airline';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../../../styles/company.module.scss';

const WIDTH = '500px';

const LABELS = {
  TOOLTIP_MESSAGE: 'У организации уже есть код этой авиакомпании, нажимая сохранить вы измените существующий',
  AEROFLOT_MESSAGE: 'При добавлении корп. кода авиакомпании Аэрофлот выписка билетов Аэрофлота в аккаунте будет автоматически переведена из NDC SU в GDS Мixvel, при этом будет взиматься дополнительная плата +185р за сегмент',
  AEROFLOT_EXIST_SCHEME: 'Ранее уже был добавлен корп. код авиакомпании Аэрофлот. При добавлении нового корп. кода авиакомпании Аэрофлот введенный ранее корп. код будет удален',
  CORP_CODE: 'Корпоративный код',
  CODE: 'Код',
};

const AviaContractForm = ({
  aviaContracts,
  aviaContractData: {
    activeAviaContract,
    activeAviaContract: {
      IsDiscount,
      Iata,
      Id,
      Number,
    },
    loading,
    validation,
  },
  onClose,
  onSave,
  onChange,
}) => {
  const [programs, setPrograms] = useState(getAvia(IsDiscount, Iata));
  const [isAirlineDisabled, setIsAirlineDisabled] = useState(false);
  const [isProgramDisabled, setIsProgramDisabled] = useState(false);

  const changePrograms = (cost) => {
    if (cost === 0) return AVIA_COMPANY_CONTRACT_MILE;

    if (cost === 2) return AVIA_COMPANY_CONTRACT_S7;

    if (cost === undefined) return DEFAULT_AVIA_COMPANY_CONTRACT;

    return AVIA_COMPANY_CONTRACT;
  };

  const handleSelect = (field, cost) => {
    if (field === FIELDS.IsDiscount) {
      setPrograms(changePrograms(cost));
      onChange(FIELDS.Iata, -1);
    }

    if (field === FIELDS.IsDiscount && cost === undefined) {
      setPrograms(changePrograms(cost));
    }

    // Если выбрана S7 Corp, то автоматом выбираем S7
    if (field === FIELDS.IsDiscount && cost === 2) {
      onChange(FIELDS.Iata, 0);
      setIsAirlineDisabled(true);
    }

    if (field === FIELDS.IsDiscount && cost !== 2) {
      setIsAirlineDisabled(false);
    }

    // Если выбрана S7, то автоматом выбираем S7 Corp
    // Но это костыль, тк форма требует изначально выбрать программу
    // И эта шляпа должна работать только если используется дефолтный список компаний
    if (programs === DEFAULT_AVIA_COMPANY_CONTRACT && field === FIELDS.Iata && cost === 0) {
      onChange(FIELDS.IsDiscount, 2);
      onChange(FIELDS.Iata, 0);
      setIsProgramDisabled(true);
    }

    if (programs === DEFAULT_AVIA_COMPANY_CONTRACT && field === FIELDS.Iata && cost !== 0) {
      onChange(FIELDS.IsDiscount, -1);
      setIsProgramDisabled(false);
    }

    return onChange(field, cost);
  };

  const renderTooltip = (label) => {
    const findAviaContract = Iata > -1
      ? aviaContracts.find(item => item.Iata === Iata)
      : '';
    const rule = label === LABEL_CONTRACTS_CODE.SAVE_CODE && findAviaContract && !Id;

    return rule && (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.TOP }
      >
        { LABELS.TOOLTIP_MESSAGE }
      </Tooltip>
    );
  };

  const renderButton = (
    styleBlock,
    styleButton,
    label,
    onClick,
    load,
    index,
  ) => (
    <div className={ 'sw-tooltip-wrapper' }>
      <AjaxButton
        className={ styleButton }
        label={ label }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ () => onClick(index) }
        loading={ load }
      />
      { renderTooltip(label) }
    </div>
  );

  const renderAeroflotMessage = () => {
    const isAeroflot = IsDiscount === 0 || IsDiscount === 1;
    const isRealAeroflot = Iata === 0 && isAeroflot;

    if (!isRealAeroflot) return null;

    return (
      <div>
        { LABELS.AEROFLOT_MESSAGE }
      </div>
    );
  };

  const renderAeroflotExistSchemeMessage = () => {
    const existAeroflotScheme = aviaContracts.some(item => item.Iata === 0);

    if (!existAeroflotScheme) return null;

    return (
      <div className={ styles.aeroflot_exist_scheme }>
        { LABELS.AEROFLOT_EXIST_SCHEME }
      </div>
    );
  };

  const choiceProgram = (item, iata) => {
    if (iata === AIRLINE_PROVIDERS.S7) return AVIA_COMPANY_CONTRACT_S7;

    return item.cost === iata;
  };

  const inValidProgram = validation
    ? lodashReplaces.getValueInObjByPath(validation, FIELDS.IsDiscount)
    : '';

  const nameProgram = AVIA_PROGRAMM_CONTRACT.find(i => i.cost === IsDiscount);

  const inValidAvia = validation
    ? lodashReplaces.getValueInObjByPath(validation, FIELDS.Iata)
    : '';

  const nameAvia = programs.find(i => choiceProgram(i, Iata));

  const currentLabelCondition = nameAvia ? nameAvia.name : '';

  return (
    <Dialog onClick={ onClose } width={ WIDTH }>
      <div className={ styles.avia_contract_wrap }>
        <div className={ styles.avia_contract_header }>
          { LABELS.CORP_CODE }
        </div>
        <div className={ styles.avia_contract_action }>
          <Suggest
            withScroll
            disabled={ !!Id || isProgramDisabled }
            title={ AVIA_CONTRACT_FIELDS.PROGRAM }
            valid={ inValidProgram }
            suggests={ AVIA_PROGRAMM_CONTRACT }
            currentLabel={ nameProgram ? nameProgram.name : '' }
            onSelect={ ({ cost }) => handleSelect(FIELDS.IsDiscount, cost) }
          />
        </div>
        <div className={ styles.avia_contract_action }>
          <Suggest
            withScroll
            disabled={ !!Id || isAirlineDisabled }
            title={ AVIA_CONTRACT_FIELDS.AVIA_COMPANY }
            valid={ inValidAvia }
            suggests={ programs }
            currentLabel={ currentLabelCondition }
            onSelect={ ({ cost }) => handleSelect(FIELDS.Iata, cost) }
          />
        </div>
        <div className={ styles.avia_label }>
          <label className={ styles.avia_code_number }>
            { LABELS.CODE }
          </label>
          <Input
            type={ COMPONENTS.INPUT.TYPE.TEXT }
            field={ FIELDS.Number }
            value={ Number }
            label=''
            onChange={ (e, field, value) => onChange(FIELDS.Number, value) }
            valid={ validation[FIELDS.Number] }
          />
        </div>
        { renderAeroflotMessage() }
        { renderAeroflotExistSchemeMessage() }
        <div className={ styles.footer }>
          { renderButton(
            styles['col-1-3'],
            styles.button,
            LABEL_CONTRACTS_CODE.SAVE_CODE,
            () => onSave(activeAviaContract),
            loading,
            ) }
          { renderButton(
            styles['col-1-3'],
            styles.button,
            DECISION_BUTTONS_CAMEL.LABELS.CANCEL,
            onClose,
            loading,
            ) }
        </div>
      </div>
    </Dialog>
  );
};

AviaContractForm.propTypes = {
  aviaContracts: PropTypes.array.isRequired,
  aviaContractData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AviaContractForm;
