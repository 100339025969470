import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, Tooltip } from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import lodashReplaces from '../../../../../bi/utils/lodashReplaces';
import { setSelectMenuStyle } from '../../../../../bi/utils/account';

import Suggest from '../../../../../components/Suggest';
import RangeDatePicker from '../../../../../components/RangeDatePicker';
import UploadForm from '../../../../../components/UploadForm';
import Input from '../../../../../components/input';
import AjaxButton from '../../../../../components/ajaxButton';

import {
  COMMISSION_SELECT,
  FIELD_TEXT_HOTEL_CONTRACT,
  FIELDS,
  REQUIRED_EXTENSIONS,
  UPLOAD_FORM,
  LABELS_HOTEL_CONTRACT,
  TYPE_SELECT,
} from '../../../../../bi/constants/profile';
import COMPONENTS from '../../../../../bi/constants/components';
import { ACCOUNTTEXT } from '../../../../../bi/constants/account';

import HotelRate from './hotelRate';

import styles from '../../../styles/company.module.scss';

const WIDTH = '1000px';

class HotelContractForm extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    hotelContractData: PropTypes.object.isRequired,
    companyData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    displayRates: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showInput: false,
      accountInfo: {
        suggestItems: [],
        accountAutocomplete: '',
        currentAccountGroup: null,
      },
      error: '',
      company: {
        label: '',
        suggests: [],
      },
      selectedCompany: [],
      companyContractAdd: [],
      isTravelline: props.hotelContractData.activeHotelContract.isTravelline,
    };
  }

  componentDidMount() {
    const { hotelContractData: { activeHotelContract: { HotelId } } } = this.props;

    if (HotelId) {
      this.getRatesFromAanda(HotelId);
    }
  }

  getRatesFromAanda = async (id) => {
    await this.props.companyService.getRatesFromAanda(id);
  }

  handleChangeField = (field, value) => this.props.companyService.changeFieldHotelContractData(`${FIELDS.ACTIVE_HOTEL_CONTRACT}.${field}`, value);

  handleChangeDateField = (field, value) => this.props.companyService.changeFieldDateHotelContractData(`${FIELDS.ACTIVE_HOTEL_CONTRACT}.${field}`, value);

  handleChangeFieldRate = (field, value, i, vatRate) =>
    this.props.companyService.changeFieldHotelRate(field, value, i, vatRate);

  handleChangeDateFieldRate = (field, value, idx) => this.props.companyService.changeRangeDateFieldHotelRate(field, value, idx);

  handleAddPerionRate = index => this.props.companyService.addPerionRate(index);

  handleDeletePeriodRate = (indRate, indPeriod) =>
    this.props.companyService.deletePeriodRate(indRate, indPeriod);

  handleChangePeriodRate = (field, value, indRate, indPeriod) =>
    this.props.companyService.changePeriodRate(field, value, indRate, indPeriod);

  handleAddFields = (field, value, i) => {
    const { companyService } = this.props;

    return companyService.hotelContractUpdateRates(field, value, i);
  };

  handleRemoveField = (field, value, i) => this.props.companyService.hotelRemoveContractRates(field, value, i);

  handleSaveContract = (IsDraft) => {
    const { companyService, accountService, companyData: { companyId, accountId } } = this.props;

    accountService.loadAccount(accountId);

    return companyService.preSaveContract(companyId, accountId, IsDraft, this.state.isTravelline);
  };

  handleCopyContract = (IsDraft, companyId, accountId) => {
    const { companyService, accountService } = this.props;
    const { companyContractAdd } = this.state;

    companyContractAdd.push(companyId);

    accountService.loadAccount(accountId);

    return companyService.preCopyContract(companyId, accountId, IsDraft);
  };

  handleDeleteContract = () => {
    const { companyService, accountService, hotelContractData, companyData: { accountId } } = this.props;

    accountService.loadAccount(accountId);

    return companyService.deleteContract(hotelContractData.activeHotelContract.Id);
  };

  handleAddInput = value => () => this.setState({
    showInput: value,
  });

  handleClearInput = value => () => this.setState({
    showInput: value,
    company: {
      label: '',
      suggests: [],
    },
  });

  handleAddRate = () => this.props.companyService.addRate();

  handleCopyRate = index => this.props.companyService.copyRate(index);

  handleDeleteRate = index => this.props.companyService.hotelDeleteRate(index);

  handleFileAdded = async (file) => {
    await this.props.companyService.uploadHotelContractFile(file);
  };

  handleDownload = () => {
    const { companyService, hotelContractData: { activeHotelContract: { FileId } } } = this.props;

    return companyService.downloadHotelContract(FileId);
  };

  handleChangeHotelSuggest = async (value) => {
    const { hotelsService: { autocompleteHotelByContract }, companyService } = this.props;
    const fields = [
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.HOTEL_NAME}`, value },
      { path: `${FIELDS.HOTEL_NAME_AUTOCOMPLETE.LABEL}`, value },
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.HOTEL_ID}`, value: null },
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.BOOKING_ID}`, value: null },
    ];

    await companyService.changeFieldsHotelContractData(fields);
    const suggest = await autocompleteHotelByContract(value);
    companyService.changeFieldHotelContractData(`${FIELDS.HOTEL_NAME_AUTOCOMPLETE.SUGGEST}`, suggest);
  };

  handleSelectHotelSuggest = (value) => {
    const { companyService } = this.props;

    companyService.deleteRatesFromAanda();
    companyService.selectHotelSuggest(value);

    this.getRatesFromAanda(value.Id);
  }

  handleChangeSuggest = (value) => {
    this.setState({
      company: {
        ...this.state.company,
        label: value,
      },
    }, () => {
      this.props.hotelsService.autocompleteCompany(this.state.company.label).then((res) => {
        this.setState({
          company: {
            ...this.state.company,
            suggests: res,
          },
        });
      });
    });
  };

  handleSelectSuggest = (cValue, value) => {
    const { selectedCompany } = this.state;
    const {
      ShortCompanyName,
      CompanyName,
      CompanyId,
      AccountId,
    } = value;

    const companyName = ShortCompanyName || CompanyName;
    const list = [...selectedCompany];

    list.push({
      companyContractName: companyName,
      companyId: CompanyId,
      accountId: AccountId,
    });

    this.setState({
      selectedCompany: list,
    });
  };

  handleChangeCheckbox = (value) => {
    this.setState({
      isTravelline: value,
    });
  }

  renderAddButton = () => (
    <div className={ styles.add_button }>
      <button className={ styles.button_add_i } onClick={ this.handleAddRate }>
        <i className='material-icons'>add</i>
      </button>
      <Button
        className={ styles.btn_add }
        label={ LABELS_HOTEL_CONTRACT.ADD_RATE }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ this.handleAddRate }
      />
    </div>
  );

  renderDownloadButton = () => {
    const { hotelContractData: { loading, activeHotelContract } } = this.props;

    return !!activeHotelContract.FileId && (
      <div className={ styles.download_button }>
        { LABELS_HOTEL_CONTRACT.PROMPT }
        <AjaxButton
          label={ LABELS_HOTEL_CONTRACT.DOWNLOAD }
          loading={ loading }
          onClick={ this.handleDownload }
          theme={ COMPONENTS.BUTTON.THEME.SECOND }
        />
      </div>
    );
  };

  renderRangeDatePicker = ({ startDate, endDate, label, fieldStart, fieldEnd, valid, onChange }) => {
    let validHtml = null;
    if (valid) {
      validHtml = (
        <span className='error-msg'> {valid} </span>
      );
    }

    return (
      <div>
        <RangeDatePicker
          startDate={ startDate }
          endDate={ endDate }
          onChange={ onChange }
          placeholderNameFrom={ ACCOUNTTEXT.FROM }
          placeholderNameTo={ ACCOUNTTEXT.TO }
          labelName={ label }
          fieldStart={ fieldStart }
          fieldEnd={ fieldEnd }
        />
        { validHtml }
      </div>
    );
  }

  renderRateDatePickerForm = (startDate, endDate, label, indRate, indPeriod) => {
    const propsRangeDatePicker = {
      startDate,
      endDate,
      label,
      fieldStart: FIELDS.START_LIFE_TIME,
      fieldEnd: FIELDS.END_LIFE_TIME,
      onChange: (field, value) => this.handleChangePeriodRate(field, value, indRate, indPeriod),
    };

    return this.renderRangeDatePicker({ ...propsRangeDatePicker });
  }

  renderDatePickerForm = (startDate, endDate, label) => {
    const { validation } = this.props.hotelContractData;

    const propsRangeDatePicker = {
      startDate,
      endDate,
      label,
      fieldStart: FIELDS.START,
      fieldEnd: FIELDS.END,
      valid: validation.Start,
      onChange: this.handleChangeDateField,
    };

    return this.renderRangeDatePicker({ ...propsRangeDatePicker });
  };

  renderInput = (title, magnitude, label, isRate = false, index) => {
    const { validation } = this.props.hotelContractData;
    const selectChange = isRate ? this.handleChangeFieldRate : this.handleChangeField;
    const validRates = validation.Rates.length > index ? validation.Rates[index][title] : '';
    const valid = isRate ? validRates : validation[title];

    return (
      <Input
        type={ TYPE_SELECT[title] }
        field={ title }
        value={ magnitude }
        label={ label }
        onChange={ (e, field, value) => selectChange(title, value, index) }
        valid={ valid }
      />
    );
  };

  renderInputRate = (title, magnitude, label, indRate, indPeriod) => {
    const { hotelContractData: { validation } } = this.props;

    const isNeedValidation = validation.Rates.length > indRate &&
      validation.Rates[indRate].Periods.length > indPeriod;
    const validRates = isNeedValidation ?
      validation.Rates[indRate].Periods[indPeriod][title] :
      '';

    return (
      <Input
        type={ TYPE_SELECT[title] }
        field={ title }
        value={ magnitude }
        label={ label }
        onChange={ (e, field, value) => this.handleChangePeriodRate(title, value, indRate, indPeriod) }
        valid={ validRates }
        className={ styles.period_input }
      />
    );
  }

  renderSelect = (title, content, path, item, isRate, index) => {
    const { validation } = this.props.hotelContractData;
    const selectChange = isRate ? this.handleChangeFieldRate : this.handleChangeField;
    const selectValidation = isRate ? validation.Rates[index] : validation;
    const inValid = selectValidation ? lodashReplaces.getValueInObjByPath(selectValidation, path) : '';
    const value = lodashReplaces.getValueInObjByPath(item, path);
    const currentLabel = value !== '' ? content.find((i) => {
      if (path === FIELDS.VAT) {
        return !!i.cost === !!value && i.vatRate === +item.VatRate;
      }

      return i.cost === Number(value);
    }) : '';

    return (
      <Suggest
        title={ title }
        valid={ inValid }
        suggests={ content }
        currentLabel={ currentLabel ? currentLabel.name : '' }
        onSelect={ ({ cost, vatRate }) => selectChange(path, cost, index, vatRate) }
        withScroll
      />
    );
  };

  renderTooltip = () => (
    <Tooltip
      position={ COMPONENTS.TOOLTIP.POSITION.TOP }
    >
      Добавьте и заполните рейт
    </Tooltip>
  );

  renderButton = (styleBlock, styleButton, label, onClick, loading, index) => {
    const { activeHotelContract: { Rates } } = this.props.hotelContractData;
    const disabled = Rates.length === 0 && label === LABELS_HOTEL_CONTRACT.SAVE_CONTRACT;
    const tooltipHtml = disabled && this.renderTooltip();

    return (
      <div className={ `${styleBlock} sw-tooltip-wrapper` }>
        { tooltipHtml }
        <AjaxButton
          className={ styleButton }
          label={ label }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => onClick(index) }
          loading={ loading }
          disabled={ disabled }
        />
        { tooltipHtml }
      </div>
    );
  };

  renderCloseCompaniesButton = () => (
    <div className={ styles.add_button }>
      <button className={ styles.button_add_i } onClick={ this.handleClearInput(false) }>
        <i className='material-icons'>close</i>
      </button>
    </div>
  );

  renderAddCompaniesButton = () => (
    <div className={ styles.add_button }>
      <button className={ styles.button_add_i } onClick={ this.handleAddInput(true) }>
        <i className='material-icons'>add</i>
      </button>
    </div>
  );

  renderHotelRate = (item, index) => {
    const { hotelContractData, displayRates } = this.props;
    const { roomTypeIds, validation, activeHotelContract: { Id }, activeHotelContract, ratesAanda } = hotelContractData;
    const validRate = validation.Rates.find((rate, i) => i === index);
    const rateCompares = activeHotelContract.Rates[index].Compares.map(({ Code }) => Code.toString());

    return (
      <div className={ styles.rate_border } key={ index }>
        <HotelRate
          item={ item }
          ratesFromAanda={ ratesAanda }
          rateCompares={ rateCompares }
          renderSelect={ this.renderSelect }
          onChangeFields={ this.handleChangeFieldRate }
          onDeleteRate={ this.handleDeleteRate }
          onCopyRate={ this.handleCopyRate }
          renderDatePickerForm={ this.renderRateDatePickerForm }
          renderInput={ this.renderInput }
          onRemoveField={ this.handleRemoveField }
          renderDeleteButton={ this.renderButton }
          onAddFields={ this.handleAddFields }
          onAddPeriodRate={ this.handleAddPerionRate }
          onDeletePeriod={ this.handleDeletePeriodRate }
          renderInputRate={ this.renderInputRate }
          isRate
          displayRates={ displayRates }
          index={ index }
          roomTypeIds={ roomTypeIds }
          validation={ validRate }
          contractId={ Id }
        />
      </div>
    );
  };

  renderCompany = (item, index, handleCopyContract, loading, disabled) => {
    const { companyContractAdd } = this.state;
    const {
      companyId,
      companyContractName,
      accountId,
    } = item;

    const companyContract = companyContractAdd.includes(companyId);
    const button = companyContract === true ? this.renderButton(styles['col-1-4'], styles.button, LABELS_HOTEL_CONTRACT.COPIED_CONTRACT, disabled, loading)
      : this.renderButton(styles['col-1-4'], styles.button, LABELS_HOTEL_CONTRACT.COPY_CONTRACT, () => this.handleCopyContract(false, companyId, accountId), loading);

    return (
      <li key={ index } className={ styles.item_contract }>
        <div>{companyContractName} ({companyId})</div>
        { button }
      </li>
    );
  }

  renderAutoCompleteHotel = () => {
    const { validation, activeHotelContract, hotelNameAutocomplete: { label, suggests } } = this.props.hotelContractData;
    const { HotelName, HotelId } = validation;
    const newSuggests = suggests || [];
    const validHotelNameHtml = (HotelName || HotelId) && <span className={ styles['error-msg_iata'] }>{ HotelName || HotelId }</span>;
    const renderItem = item => (
      <div className='autocomplete-item'>
        { `${item.Name} - ${item.FullName}` }
      </div>
    );

    return (
      <div className={ `${styles['auto-input']} ${styles['hotel-input']} ${HotelName || HotelId ? styles['no-valid'] : ''}` }>
        <Autocomplete
          menuStyle={ setSelectMenuStyle(newSuggests) }
          value={ activeHotelContract.HotelName || label }
          items={ newSuggests }
          getItemValue={ item => item.Name }
          onSelect={ (field, value) => this.handleSelectHotelSuggest(value) }
          onChange={ (event, value) => this.handleChangeHotelSuggest(value) }
          renderItem={ renderItem }
        />
        { validHotelNameHtml }
      </div>
    );
  };

  renderAutoCompleteCompany = () => {
    const { company } = this.state;
    const autoCompleteItem = (item) => {
      const {
        ShortCompanyName,
        CompanyName,
        CompanyId,
      } = item;
      const companyName = ShortCompanyName || CompanyName;

      return (
        <div className='autocomplete-item'>
          {`${companyName} (${CompanyId})`}
        </div>
      );
    };

    return (
      <div className={ styles['auto-input'] }>
        <Autocomplete
          menuStyle={ setSelectMenuStyle(company.suggests) }
          value={ company.label }
          items={ company.suggests }
          getItemValue={ item => item.ShortCompanyName || item.CompanyName }
          onSelect={ (cValue, value) => this.handleSelectSuggest(cValue, value) }
          onChange={ (event, value) => this.handleChangeSuggest(value) }
          renderItem={ autoCompleteItem }
        />
      </div>
    );
  };

  renderButtons = () => {
    const { onClose, hotelContractData: { loading, activeHotelContract } } = this.props;

    const { Id, IsDraft } = activeHotelContract;

    const draftContract = (IsDraft || Id === 0) && this.renderButton(styles['col-1-4'], styles.button, LABELS_HOTEL_CONTRACT.SAVE_DRAFT, () => this.handleSaveContract(true), loading);
    const deleteContract = Id > 0 && this.renderButton(styles['col-1-4'], styles.button, LABELS_HOTEL_CONTRACT.DELETE_CONTRACT, this.handleDeleteContract, loading);

    return (
      <div className={ `${styles.row} ${styles['row-between']}` }>
        { this.renderButton(
          styles['col-1-4'],
          styles.button,
          LABELS_HOTEL_CONTRACT.SAVE_CONTRACT,
          () => this.handleSaveContract(false), loading
        ) }
        { draftContract }
        { deleteContract }
        { this.renderButton(styles['col-1-4'], styles.button, LABELS_HOTEL_CONTRACT.CANCEL, onClose, loading) }
      </div>
    );
  };

  render() {
    const { companyData: { companyName }, hotelContractData: { activeHotelContract, uploadedFile } } = this.props;
    const { selectedCompany, showInput, isTravelline } = this.state;
    const { Rates, BookingId, Commission, Start, End } = activeHotelContract;
    const autoCompleteCompany = showInput === true && this.renderAutoCompleteCompany();
    const autoCompleteButton = showInput === false ? this.renderAddCompaniesButton() : this.renderCloseCompaniesButton();

    return (
      <Dialog onClick={ () => {} } width={ WIDTH }>
        <div className={ styles.hotel_contract_wrap }>
          <div className={ styles['hotel-contract_header'] }>Корпоративный договор</div>
          { this.renderButtons() }
          <div className={ styles.companies }>
            <div className={ styles }><label>Название компании:</label> { companyName }</div>
            { autoCompleteCompany }
            { autoCompleteButton }
          </div>
          <ul className={ styles.list }>
            { selectedCompany.map(this.renderCompany) }
          </ul>
          <div className={ styles.isTravelLIne_checkbox }>
            <Checkbox
              value={ isTravelline }
              onChange={ this.handleChangeCheckbox }
            >
              { FIELD_TEXT_HOTEL_CONTRACT.USING_TRAVELINE }
            </Checkbox>
          </div>
          <div className={ `${styles.hotel_label} ${styles.data_container}` }>
            <label className={ styles.autocomplete_name_hotel }>Название отеля:</label>
            { this.renderAutoCompleteHotel() }
          </div>
          <div className={ styles.hotel_label }><label>id booking:</label> { BookingId || '' }</div>
          { Rates.map(this.renderHotelRate) }
          { this.renderAddButton() }
          <div>
            <label>{ FIELD_TEXT_HOTEL_CONTRACT.COMMISSION }</label>
            <div className={ styles.data_container }>
              <div className={ styles.commission_select }>
                { this.renderInput(FIELDS.COMMISSION, Commission, '') }
              </div>
              <div className={ styles.hotel_contract_action }>
                { this.renderSelect('', COMMISSION_SELECT, FIELDS.IS_PER_SENT, activeHotelContract) }
              </div>
            </div>
          </div>
          { this.renderDatePickerForm(Start, End, FIELD_TEXT_HOTEL_CONTRACT.TERM_OF_CONTRACT) }
          <div className={ `${styles.row} ${styles.wrap_upload}` }>
            <label className={ styles.label_scan }>Скан договора: </label>
            <div className={ styles['col-1-2'] }>
              <UploadForm
                extensions={ REQUIRED_EXTENSIONS }
                onFileAdded={ this.handleFileAdded }
                uploaded={ uploadedFile }
                chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
                description={ UPLOAD_FORM.DESCRIPTION }
                invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
              />
            </div>
          </div>
          { this.renderDownloadButton() }
          { this.renderButtons() }
        </div>
      </Dialog>
    );
  }
}

export default HotelContractForm;
